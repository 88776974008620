.emoji-mart,
.emoji-mart * {
  box-sizing: border-box;
  line-height: 1.15;
}

.emoji-mart {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", sans-serif;
  font-size: 16px;
  display: inline-block;
  color: #222427;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  background: #fff;
}

.emoji-mart .emoji-mart-emoji {
  padding: 6px;
}

.emoji-mart-bar {
  border: 0 solid #d9d9d9;
}

.emoji-mart-bar:first-child {
  border-bottom-width: 1px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.emoji-mart-bar:last-child {
  border-top-width: 1px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.emoji-mart-anchors {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 6px;
  line-height: 0;
}

.emoji-mart-anchor {
  position: relative;
  display: block;
  flex: 1 1 auto;
  color: #858585;
  text-align: center;
  padding: 12px 4px;
  overflow: hidden;
  transition: color 0.1s ease-out;
  margin: 0;
  box-shadow: none;
  background: none;
  border: none;
}

.emoji-mart-anchor:focus {
  outline: 0;
}

.emoji-mart-anchor:hover,
.emoji-mart-anchor:focus,
.emoji-mart-anchor-selected {
  color: #464646;
}

.emoji-mart-anchor-selected .emoji-mart-anchor-bar {
  bottom: 0;
}

.emoji-mart-anchor-bar {
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #464646;
}

.emoji-mart-anchors i {
  display: inline-block;
  width: 100%;
  max-width: 22px;
}

.emoji-mart-anchors svg,
.emoji-mart-anchors img {
  fill: currentColor;
  height: 18px;
}

.emoji-mart-scroll {
  overflow-y: scroll;
  height: 270px;
  padding: 0 6px 6px 6px;
  will-change: transform; /* avoids "repaints on scroll" in mobile Chrome */
}

.emoji-mart-search {
  margin-top: 6px;
  padding: 0 6px;
  position: relative;
}

.emoji-mart-search input {
  font-size: 16px;
  display: block;
  width: 100%;
  padding: 5px 25px 6px 10px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  outline: 0;
}

.emoji-mart-search input,
.emoji-mart-search input::-webkit-search-decoration,
.emoji-mart-search input::-webkit-search-cancel-button,
.emoji-mart-search input::-webkit-search-results-button,
.emoji-mart-search input::-webkit-search-results-decoration {
  /* remove webkit/blink styles for <input type="search">
   * via https://stackoverflow.com/a/9422689 */
  -webkit-appearance: none;
}

.emoji-mart-search-icon {
  position: absolute;
  top: 3px;
  right: 11px;
  z-index: 2;
  padding: 2px 5px 1px;
  border: none;
  background: none;
}

.emoji-mart-category .emoji-mart-emoji span {
  z-index: 1;
  position: relative;
  text-align: center;
  cursor: default;
}

.emoji-mart-category .emoji-mart-emoji:hover:before {
  z-index: 0;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f4f4f4;
  border-radius: 100%;
}

.emoji-mart-category-label {
  z-index: 2;
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.emoji-mart-category-label span {
  display: block;
  width: 100%;
  font-weight: 500;
  padding: 5px 6px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.95);
}

.emoji-mart-category-list {
  margin: 0;
  padding: 0;
}

.emoji-mart-category-list li {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-block;
}

.emoji-mart-emoji {
  position: relative;
  display: inline-block;
  font-size: 0;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  box-shadow: none;
}

.emoji-mart-emoji-native {
  font-family: "Segoe UI Emoji", "Segoe UI Symbol", "Segoe UI", "Apple Color Emoji", "Twemoji Mozilla", "Noto Color Emoji", "Android Emoji";
}

.emoji-mart-no-results {
  font-size: 14px;
  text-align: center;
  padding-top: 70px;
  color: #858585;
}

.emoji-mart-no-results .emoji-mart-category-label {
  display: none;
}

.emoji-mart-no-results .emoji-mart-no-results-label {
  margin-top: 0.2em;
}

.emoji-mart-no-results .emoji-mart-emoji:hover:before {
  content: none;
}

.emoji-mart-preview {
  position: relative;
  height: 70px;
}

.emoji-mart-preview-emoji,
.emoji-mart-preview-data,
.emoji-mart-preview-skins {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.emoji-mart-preview-emoji {
  left: 12px;
}

.emoji-mart-preview-data {
  left: 68px;
  right: 12px;
  word-break: break-all;
}

.emoji-mart-preview-skins {
  right: 30px;
  text-align: right;
}

.emoji-mart-preview-skins.custom {
  right: 10px;
  text-align: right;
}

.emoji-mart-preview-name {
  font-size: 14px;
}

.emoji-mart-preview-shortname {
  font-size: 12px;
  color: #888;
}

.emoji-mart-preview-shortname + .emoji-mart-preview-shortname,
.emoji-mart-preview-shortname + .emoji-mart-preview-emoticon,
.emoji-mart-preview-emoticon + .emoji-mart-preview-emoticon {
  margin-left: 0.5em;
}

.emoji-mart-preview-emoticon {
  font-size: 11px;
  color: #bbb;
}

.emoji-mart-title span {
  display: inline-block;
  vertical-align: middle;
}

.emoji-mart-title .emoji-mart-emoji {
  padding: 0;
}

.emoji-mart-title-label {
  color: #999A9C;
  font-size: 26px;
  font-weight: 300;
}

.emoji-mart-skin-swatches {
  font-size: 0;
  padding: 2px 0;
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  background-color: #fff;
}

.emoji-mart-skin-swatches.custom {
  font-size: 0;
  border: none;
  background-color: #fff;
}

.emoji-mart-skin-swatches.opened .emoji-mart-skin-swatch {
  width: 16px;
  padding: 0 2px;
}

.emoji-mart-skin-swatches.opened .emoji-mart-skin-swatch.selected:after {
  opacity: 0.75;
}

.emoji-mart-skin-swatch {
  display: inline-block;
  width: 0;
  vertical-align: middle;
  transition-property: width, padding;
  transition-duration: 0.125s;
  transition-timing-function: ease-out;
}

.emoji-mart-skin-swatch:nth-child(1) {
  transition-delay: 0s;
}

.emoji-mart-skin-swatch:nth-child(2) {
  transition-delay: 0.03s;
}

.emoji-mart-skin-swatch:nth-child(3) {
  transition-delay: 0.06s;
}

.emoji-mart-skin-swatch:nth-child(4) {
  transition-delay: 0.09s;
}

.emoji-mart-skin-swatch:nth-child(5) {
  transition-delay: 0.12s;
}

.emoji-mart-skin-swatch:nth-child(6) {
  transition-delay: 0.15s;
}

.emoji-mart-skin-swatch.selected {
  position: relative;
  width: 16px;
  padding: 0 2px;
}

.emoji-mart-skin-swatch.selected:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 4px;
  height: 4px;
  margin: -2px 0 0 -2px;
  background-color: #fff;
  border-radius: 100%;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease-out;
}

.emoji-mart-skin-swatch.custom {
  display: inline-block;
  width: 0;
  height: 38px;
  overflow: hidden;
  vertical-align: middle;
  transition-property: width, height;
  transition-duration: 0.125s;
  transition-timing-function: ease-out;
  cursor: default;
}

.emoji-mart-skin-swatch.custom.selected {
  position: relative;
  width: 36px;
  height: 38px;
  padding: 0 2px 0 0;
}

.emoji-mart-skin-swatch.custom.selected:after {
  content: "";
  width: 0;
  height: 0;
}

.emoji-mart-skin-swatches.custom .emoji-mart-skin-swatch.custom:hover {
  background-color: #f4f4f4;
  border-radius: 10%;
}

.emoji-mart-skin-swatches.custom.opened .emoji-mart-skin-swatch.custom {
  width: 36px;
  height: 38px;
  padding: 0 2px 0 0;
}

.emoji-mart-skin-swatches.custom.opened .emoji-mart-skin-swatch.custom.selected:after {
  opacity: 0.75;
}

.emoji-mart-skin-text.opened {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  color: #888;
  font-size: 11px;
  padding: 5px 2px;
  width: 95px;
  height: 40px;
  border-radius: 10%;
  background-color: #fff;
}

.emoji-mart-skin {
  display: inline-block;
  width: 100%;
  padding-top: 100%;
  max-width: 12px;
  border-radius: 100%;
}

.emoji-mart-skin-tone-1 {
  background-color: #ffc93a;
}

.emoji-mart-skin-tone-2 {
  background-color: #fadcbc;
}

.emoji-mart-skin-tone-3 {
  background-color: #e0bb95;
}

.emoji-mart-skin-tone-4 {
  background-color: #bf8f68;
}

.emoji-mart-skin-tone-5 {
  background-color: #9b643d;
}

.emoji-mart-skin-tone-6 {
  background-color: #594539;
}

/* For screenreaders only, via https://stackoverflow.com/a/19758620 */
.emoji-mart-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/*
 * Dark mode styles
 */
.emoji-mart-dark {
  color: #fff;
  border-color: #555453;
  background-color: #222;
}

.emoji-mart-dark .emoji-mart-bar {
  border-color: #555453;
}

.emoji-mart-dark .emoji-mart-search input {
  color: #fff;
  border-color: #555453;
  background-color: #2f2f2f;
}

.emoji-mart-dark .emoji-mart-search-icon svg {
  fill: #fff;
}

.emoji-mart-dark .emoji-mart-category .emoji-mart-emoji:hover:before {
  background-color: #444;
}

.emoji-mart-dark .emoji-mart-category-label span {
  background-color: #222;
  color: #fff;
}

.emoji-mart-dark .emoji-mart-skin-swatches {
  border-color: #555453;
  background-color: #222;
}

.emoji-mart-dark .emoji-mart-anchor:hover,
.emoji-mart-dark .emoji-mart-anchor:focus,
.emoji-mart-dark .emoji-mart-anchor-selected {
  color: #bfbfbf;
}

@font-face {
  font-family: "Nunito";
  src: url("assets/fonts/Nunito-Regular.ttf");
}
html,
body {
  height: 100%;
}

body {
  height: 100%;
  background-color: var(--primary);
  font-family: "Nunito", "Roboto", sans-serif !important;
  margin: 0;
}

::-webkit-scrollbar {
  width: 0;
}

::-webkit-scrollbar-corner {
  display: none;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: var(--purple2);
}

.color-main {
  background-color: var(--primary);
}

.bg-white {
  background-color: white !important;
}

h1,
h2,
h3 {
  margin: 0 !important;
}

.edit {
  background: white;
}

.custom-file-upload {
  cursor: pointer;
}

input[type=file] {
  display: none;
}

.pdf {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px;
}

.ml-16 {
  margin-left: 16px;
}

.mr-16 {
  margin-right: 16px;
}

.pl-16 {
  padding-left: 16px;
}

.pr-16 {
  padding-left: 16px;
}

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

mat-card,
.mat-dialog-container {
  border-radius: 32px !important;
  box-shadow: none !important;
}
mat-card ::-webkit-scrollbar,
.mat-dialog-container ::-webkit-scrollbar {
  display: none;
}

.mat-dialog-container {
  border-radius: 20px !important;
}

.mat-icon-button {
  background-color: white;
  border-radius: 100%;
  padding: 10px;
  border: none;
}

.word-break {
  word-wrap: break-word;
}

button:hover {
  background-color: var(--primary) !important;
}

.color-purple {
  background-color: var(--purple2);
}

.p-0 {
  padding: 0 !important;
}

.color-purple2-text {
  color: var(--purple3);
}

.align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog-container .mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 32px;
  overflow-y: visible;
}

.dialog-edit-channel .mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 0 32px 32px 32px;
}

.dialog-mobile .mat-bottom-sheet-container {
  border-radius: 64px 64px 0 0;
  min-width: 0;
  margin: 0 10px;
  width: 100vw;
  height: 160px;
}

.w100 {
  width: 100%;
}

input {
  border-radius: 32px;
  padding: 16px;
  outline: none;
  border: 1px solid var(--border);
}
input:hover {
  border: 1px solid var(--purple2);
}
input:focus {
  border: 1px solid var(--purple1);
}

a {
  border-radius: 32px !important;
  text-decoration: none;
  cursor: pointer;
}
a:hover {
  font-weight: bold;
}

.h100 {
  height: 100%;
}

.main-button {
  box-sizing: border-box;
  outline: none;
  border-radius: 24px !important;
  padding: 12px 24px !important;
  height: fit-content !important;
  width: fit-content !important;
  border: 1px solid var(--purple1) !important;
  color: var(--purple1) !important;
  transition: all 125ms !important;
}
.main-button:hover {
  opacity: 0.8 !important;
}
.main-button:disabled {
  color: var(--gray) !important;
  border: 1px solid var(--gray) !important;
  cursor: not-allowed;
  opacity: 0.5;
  margin-top: 2px;
}

.user-search-container-textfield {
  height: fit-content;
  width: fit-content;
  position: absolute;
  max-height: 300px;
  overflow-y: scroll;
  background-color: white;
  z-index: 2;
  left: 168px;
  bottom: 70px;
  border-radius: 32px 32px 32px 0px;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.4);
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.user-search-container-textfield::-webkit-scrollbar {
  display: none;
}

.user-container {
  display: flex;
  align-items: center;
  font-size: 16px;
  gap: 16px;
  border-radius: 32px;
  padding: 6px 24px 6px 6px;
  background-color: white;
  outline: 0;
  border: 0;
  max-width: 245px;
  cursor: pointer;
}
.user-container img {
  height: 50px;
  border-radius: 100%;
}
.user-container:hover {
  background-color: var(--primary);
}
.user-container span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.user-container-highlighted {
  background-color: var(--primary);
  color: var(--purple3);
  font-weight: 700;
}

.profile-container {
  display: flex;
  position: relative;
}

.delete-button {
  border: 1px solid red !important;
  color: red !important;
}
.delete-button:hover {
  background-color: red !important;
  color: white !important;
}

.primary {
  background-color: var(--purple1) !important;
  color: white !important;
}
.primary:hover {
  background-color: var(--purple1) !important;
}

h1 {
  font-family: "Nunito", "Roboto", sans-serif !important;
}

h2 {
  font-family: "Nunito", "Roboto", sans-serif !important;
}

:root {
  --purple1: #444df2;
  --primary: #ECEEFE;
  --border: #ADB0D9;
  --purple2: #797EF3;
  --purple3: #535AF1;
  --textGray: #686868;
  --online: #92C83E;
}

.br-32 {
  border-radius: 32px !important;
}

.d-flex {
  display: flex;
}

.j-flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.fadeIn {
  animation: fadeIn 500ms forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.more-menu-button {
  position: relative;
}

mat-drawer-inner-container {
  border-radius: 32px;
  overflow: hidden;
}

.more-menu {
  display: none;
  z-index: 2;
  border-radius: 0 32px 32px 32px;
  background: white;
  padding: 16px 24px;
  position: absolute;
  flex-direction: column;
  left: 20px;
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.2);
  top: 12px;
  gap: 8px;
  font-size: 12px;
  width: 150px;
  cursor: pointer;
}
.more-menu span {
  color: var(--purple3);
  transition: all 125ms;
}
.more-menu span:hover {
  font-weight: bold;
  transition: all 125ms;
}

.more-menu.visible {
  display: flex;
}

.offline-status {
  background-color: grey;
}

.emoji-overlay {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.emoji-overlay .emoji-mart {
  width: fit-content !important;
  max-width: 400px;
}

.reaction-emoji-container {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}
.reaction-emoji-container span {
  font-size: 14px;
}
.reaction-emoji-container .emoji-fit {
  font-size: 20px;
  margin-bottom: 3px;
}

.reaction-emoji-button {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 2px 6px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16);
  transition: all 125ms;
}
.reaction-emoji-button:hover {
  background-color: #ccc !important;
  transform: rotate(5deg);
}

.message-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.attachments {
  display: flex;
  align-items: center;
}
.attachments button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.border-right {
  border-right: 2px solid lightgrey;
}

.main-chat-container {
  overflow-y: scroll;
}
.main-chat-container::-webkit-scrollbar {
  display: none;
}

mat-drawer-container mat-drawer {
  border: 1px solid red;
}

.delete-message {
  color: red !important;
}

textarea {
  font-family: "Nunito", "Roboto", sans-serif !important;
}

.menu-mobile {
  position: absolute !important;
  width: auto;
  left: 15px;
  right: 0;
  bottom: 0;
  max-width: none !important;
  border-radius: 64px 64px 0 0 !important;
  display: flex;
  justify-content: center;
}
.menu-mobile button {
  justify-content: center;
}

.separator-mobile {
  height: 3px;
  background-color: lightgray;
  width: 40px;
  margin: 16px 0 16px 24px;
  border-radius: 32px;
}

.overlay-home {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
}