@import '@ctrl/ngx-emoji-mart/picker';

@font-face {
    font-family: 'Nunito';
    src: url('assets/fonts/Nunito-Regular.ttf');
}

html,
body {
    height: 100%;
}

body {
    height: 100%;
    background-color: var(--primary);
    font-family: 'Nunito', 'Roboto', sans-serif !important;
    margin: 0;
}

::-webkit-scrollbar {
    width: 0;
}

::-webkit-scrollbar-corner {
    display: none
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: var(--purple2);
}

.color-main {
    background-color: var(--primary);
}

.bg-white {
    background-color: white !important;
}

h1,
h2,
h3 {
    margin: 0 !important;
}

.edit {
    background: white;
}

.custom-file-upload {
    cursor: pointer;
}

input[type="file"] {
    display: none;
}

.pdf {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 10px;
}

.ml-16 {
    margin-left: 16px;
}

.mr-16 {
    margin-right: 16px;
}

.pl-16 {
    padding-left: 16px;
}

.pr-16 {
    padding-left: 16px;
}

* {
    box-sizing: border-box;
    scroll-behavior: smooth;
}

mat-card,
.mat-dialog-container {
    border-radius: 32px !important;
    box-shadow: none !important;

    ::-webkit-scrollbar{
        display: none;
    }
}

.mat-dialog-container {
    border-radius: 20px !important;
}

.mat-icon-button {
    background-color: white;
    border-radius: 100%;
    padding: 10px;
    border: none;
}

.word-break {
    word-wrap: break-word;
}

button {
    &:hover {
        background-color: var(--primary) !important;
    }
}

.color-purple {
    background-color: var(--purple2);
}

.p-0 {
    padding: 0 !important;
}

.color-purple2-text {
    color: var(--purple3);
}

.align-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dialog-container .mat-mdc-dialog-container .mdc-dialog__surface {
    border-radius: 32px;
    overflow-y: visible;
}

.dialog-edit-channel .mat-mdc-dialog-container .mdc-dialog__surface {
    border-radius: 0 32px 32px 32px;
}

.dialog-mobile .mat-bottom-sheet-container {
    border-radius: 64px 64px 0 0;
    min-width: 0;
    margin: 0 10px;
    width: 100vw;
    height: 160px;
}

.w100 {
    width: 100%;
}

input {
    border-radius: 32px;
    padding: 16px;
    outline: none;
    border: 1px solid var(--border);

    &:hover {
        border: 1px solid var(--purple2);
    }

    &:focus {
        border: 1px solid var(--purple1);
    }
}

a {
    border-radius: 32px !important;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        font-weight: bold;
    }
}

.h100 {
    height: 100%;
}

.main-button {
    box-sizing: border-box;
    outline: none;
    border-radius: 24px !important;
    padding: 12px 24px !important;
    height: fit-content !important;
    width: fit-content !important;
    border: 1px solid var(--purple1) !important;
    color: var(--purple1) !important;
    transition: all 125ms !important;

    &:hover {
        opacity: 0.8 !important;
    }

    &:disabled {
        color: var(--gray) !important;
        border: 1px solid var(--gray) !important;
        cursor: not-allowed;
        opacity: 0.5;
        margin-top: 2px;
    }
}

.user-search-container-textfield {
    height: fit-content;
    width: fit-content;
    position: absolute;
    max-height: 300px;
    overflow-y: scroll;
    background-color: white;
    z-index: 2;
    left: 168px;
    bottom: 70px;
    border-radius: 32px 32px 32px 0px;
    box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.4);
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &::-webkit-scrollbar {
        display: none;
    }
}

.user-container {
    display: flex;
    align-items: center;
    font-size: 16px;
    gap: 16px;
    border-radius: 32px;
    padding: 6px 24px 6px 6px;
    background-color: white;
    outline: 0;
    border: 0;
    max-width: 245px;
    cursor: pointer;

    img {
        height: 50px;
        border-radius: 100%;
    }

    &:hover {
        background-color: var(--primary);
    }

    span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.user-container-highlighted {
    background-color: var(--primary);
    color: var(--purple3);
    font-weight: 700;
}

.profile-container {
    display: flex;
    position: relative;
}

.delete-button {
    border: 1px solid red !important;
    color: red !important;

    &:hover {
        background-color: red !important;
        color: white !important;
    }
}

.primary {
    background-color: var(--purple1) !important;
    color: white !important;

    &:hover {
        background-color: var(--purple1) !important;
    }
}

h1 {
    font-family: 'Nunito', 'Roboto', sans-serif !important;
}

h2 {
    font-family: 'Nunito', 'Roboto', sans-serif !important;
}

:root {
    --purple1: #444df2;
    --primary: #ECEEFE;
    --border: #ADB0D9;
    --purple2: #797EF3;
    --purple3: #535AF1;
    --textGray: #686868;
    --online: #92C83E;
}

.br-32 {
    border-radius: 32px !important;
}

.d-flex {
    display: flex;
}

.j-flex-end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.fadeIn {
    animation: fadeIn 500ms forwards;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.more-menu-button {
    position: relative;
}

mat-drawer-inner-container {
    border-radius: 32px;
    overflow: hidden;
}

.more-menu {
    display: none;
    z-index: 2;
    border-radius: 0 32px 32px 32px;
    background: white;
    padding: 16px 24px;
    position: absolute;
    flex-direction: column;
    left: 20px;
    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.2);
    top: 12px;
    gap: 8px;
    font-size: 12px;
    width: 150px;
    cursor: pointer;

    span {
        color: var(--purple3);
        transition: all 125ms;

        &:hover {
            font-weight: bold;
            transition: all 125ms;
        }
    }
}

.more-menu.visible {
    display: flex;
}

.offline-status {
    background-color: grey;
}

.emoji-overlay {
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .emoji-mart {
        width: fit-content !important;
        max-width: 400px;
    }
}

.reaction-emoji-container {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;

    span {
        font-size: 14px;
    }

    .emoji-fit {
        font-size: 20px;
        margin-bottom: 3px;
    }
}

.reaction-emoji-button {
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 20px;
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 2px 6px;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16);
    transition: all 125ms;

    &:hover {
        background-color: #ccc !important;
        transform: rotate(5deg);
    }
}

.message-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.attachments {
    display: flex;
    align-items: center;

    button {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.border-right {
    border-right: 2px solid lightgrey;
}

.main-chat-container {
    overflow-y: scroll;
    // height: 100%;
    // display: flex;

    &::-webkit-scrollbar {
        display: none;
    }
}

mat-drawer-container {
    mat-drawer {
        border: 1px solid red;
    }
}

.delete-message {
    color: red !important;
}

textarea {
    font-family: 'Nunito', 'Roboto', sans-serif !important;
}

.menu-mobile {
    position: absolute !important;
    width: auto;
    left: 15px;
    right: 0;
    bottom: 0;
    max-width: none !important;
    border-radius: 64px 64px 0 0 !important;
    display: flex;
    justify-content: center;

    button {
        justify-content: center;
    }
}

.separator-mobile {
    height: 3px;
    background-color: lightgray;
    width: 40px;
    margin: 16px 0 16px 24px;
    border-radius: 32px;
}

.overlay-home {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
}